// This is included with the Parsley library itself,
// thus there is no use in adding it to your project.

Parsley.addMessages('ka', {
  defaultMessage: "ეს მნიშვნელობა, როგორც ჩანს, არასწორია.",
  type: {
    email: "ეს მნიშვნელობა უნდა იყოს სწორი ელფოსტა.",
    url: "ეს მნიშვნელობა უნდა იყოს სწორი url.",
    number: "ეს მნიშვნელობა უნდა იყოს სწორი ნომერი.",
    integer: "ეს მნიშვნელობა უნდა იყოს სწორი მთელი რიცხვი.",
    digits: "ეს მნიშვნელობა უნდა იყოს ციფრები.",
    alphanum: "ეს მნიშვნელობა უნდა იყოს ალფანუმერული."
  },
  notblank: "ეს მნიშვნელობა არ უნდა იყოს ცარიელი.",
  required: "ეს მნიშვნელობა აუცილებელია.",
  pattern: "ეს მნიშვნელობა, როგორც ჩანს, არასწორია.",
  min: "ეს მნიშვნელობა უნდა იყოს %s-ზე მეტი ან ტოლი.",
  max: "ეს მნიშვნელობა უნდა იყოს %s-ზე დაბალი ან ტოლი.",
  range: "ეს მნიშვნელობა უნდა იყოს %s-სა და %s-ს შორის.",
  minlength: "ეს მნიშვნელობა ძალიან მოკლეა. მას უნდა ჰქონდეს %s სიმბოლო ან მეტი.",
  maxlength: "ეს მნიშვნელობა ძალიან გრძელია. მას უნდა ჰქონდეს %s სიმბოლო ან ნაკლები.",
  length: "ეს მნიშვნელობის სიგრძე არასწორია. ის უნდა იყოს %s და %s სიმბოლოებს შორის.",
  mincheck: "თქვენ უნდა აირჩიოთ მინიმუმ %s არჩევანი.",
  maxcheck: "თქვენ უნდა აირჩიოთ %s არჩევანი ან ნაკლები.",
  check: "თქვენ უნდა აირჩიოთ %s და %s არჩევანი.",
  equalto: "ეს მნიშვნელობა უნდა იყოს იგივე.",
  euvatin: "ეს არ არის მოქმედი დღგ-ის საიდენტიფიკაციო ნომერი.",
});

Parsley.setLocale('ka');
